<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="6" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!-- filter model table filter propu ile eşleşir-->
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="my-date-row">
          <b-col cols="6" md="6">
            <label for="">{{ $t("Start Date Filter") }}</label>
            <flat-pickr
              v-model="start_date"
              class="form-control"
              :config="configStart"
              :placeholder="placeholder"
            />
          </b-col>
          <b-col cols="6" md="6">
            <label for="">{{ $t("End Date Filter") }}</label>
            <flat-pickr
              v-model="end_date"
              class="form-control"
              :config="configEnd"
              :placeholder="placeholder"
            />
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="filterConsumedActivities"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :empty-filtered-text="$t('There are no records matching your request!')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(consumed_status)="data">
          <!-- <template v-if="isMobile === true">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    :to="{
                      name: 'instructorEdit',
                      params: { personID: data.item.person_id },
                    }"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :to="{
                      name: 'AddProductToTrainer',
                      params: {
                        studioID: companyBranchId,
                        trainerID: data.item.person_id,
                        trainerName: data.item.full_name,
                      },
                    }"
                  >
                    <feather-icon icon="UserIcon" />
                    <span class="align-middle ml-50">{{
                      $t("Create Product")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template> -->

          <template
            v-if="isMobile === false && data.item.consumed_status == false"
          >
            <b-button
              size="sm"
              style="min-width:130px; padding-left:1px; padding-top:2px; padding-bottom:2px; "
              class=""
              value="data.item."
              variant="btn btn-outline-danger"
            >
              <span
                class="badge badge-danger"
                style="margin-top:2px; margin-right:8px; padding:5px 6px 4px 6px"
              >
                {{ data.item.consumed_quantity }} /{{
                  data.item.quantity
                }}</span
              >
              {{ $t("Consumed") }}
            </b-button>
          </template>

          <template
            v-if="isMobile === false && data.item.consumed_status == true"
          >
            <b-button
              size="sm"
              style="min-width:130px; padding:2px 5px 2px 5px; cursor: default;"
              class=""
              variant="btn btn-outline-success"
            >
              <span
                class="badge badge-success"
                style="margin-top:2px; margin-right:8px; padding:5px 6px 4px 6px"
              >
                {{ data.item.consumed_quantity }} /{{ data.item.quantity }}
              </span>
              {{ $t("Not Consumed") }}
            </b-button>
          </template>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm=""
            class="d-flex align-items-right justify-content-right radius 0"
          >
            <small v-if="$i18n.locale === 'tr'" class="mt-1"
              >içerisindeki {{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
                currentPage * perPage
              }}
              arası ({{ currentPage }}.sayfa) gösteriliyor
            </small>
            <small v-if="$i18n.locale === 'en'" class="mt-1"
              >showing {{ currentPage * 1 }}-{{ currentPage * perPage }} (page
              {{ currentPage }}) of {{ totalRows }} records</small
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius
              0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";
import Ripple from "vue-ripple-directive";
import i18n from "@/libs/i18n";
import MoneyFormat from "@/functions/MoneyFormat";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import "flatpickr/dist/flatpickr.css";
import dateformat from "dateformat";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,

    vSelect,
    flatPickr,
  },

  data() {
    return {
      language: i18n.locale,
      placeholder: "dd.mm.YYYY",
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100, 500],
      perPage: 25,
      filterOn: [],
      locale: this.$i18n.locale,
      tableColumns: [
        // { key: "name", label: this.$t("Name"), sortable: true },
        // { key: "last_name", label: this.$t("Surname"), sortable: true },
        {
          key: "customer_product_sales_id",
          label: this.$t("ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "product_price_id",
          label: this.$t("PRODUCT PRICE ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "trainer_id",
          label: this.$t("TRAINER ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "trainer_person_id",
          label: this.$t("TRAINER PERSON ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "activity_id",
          label: this.$t("ACTIVITY ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "customer_person_id",
          label: this.$t("CUSTOMER PERSON ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "activity_name",
          label: this.$t("ACTIVITY NAME"),
          sortable: true,
        },
        { key: "created_date", label: this.$t("CREATED DATE"), sortable: true },
        // { key: "trainer_name", label: this.$t("Trainer Name"),   sortable: true},
        {
          key: "customer_name",
          label: this.$t("Customer Name"),
          sortable: true,
        },
        { key: "email", label: this.$t("email"), sortable: true },
        { key: "phone_number", label: this.$t("Phone Number"), sortable: true },
        //{ key: "quantity", label: this.$t("QUANTITY") },
        // { key: "consumed_quantity" , label: this.$t("CONSUMED QUANTITY")},
        {
          key: "consumed_status",
          label: this.$t("Consume Status"),
          sortable: true,
        },
      ],

      companyBranchID: null,

      consumedActivities: [],
      filterConsumedActivities: [],

      start_date: null,
      end_date: null,

      configStart: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        minDate: "01.01.1940",
        //  maxDate: "today"
      },

      configEnd: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        //maxDate:this.moment().add(3,'months').format("L"), //"today",
        minDate: "01.01.1940",
      },
    };
  },
  watch: {
    filterConsumedActivities(val) {
      this.totalRows = val.length;
    },

    // new Date('01.01.2020') < new Date('01.02.2020')
    // Ben pasife çektim
    start_date(val) {
      this.configEnd.minDate = val;
      this.filterConsumedActivities = [];

      //this.configEnd.minDate = val; //bitiş tarihininin başlangıç değeri en az başlangıç tarihi değerine eşit olabilir
      this.consumedActivities.map((el) => {
        var prmStartDate = this.$functions.ConvertToEnglishDateFormat(val);
        var prmTickStartDate = this.moment(prmStartDate).format("X");
        //console.log("Start Date => "+ prmStartDate + " Span Start Date"  + prmTickStartDate)
        var prmEndDate = this.$functions.ConvertToEnglishDateFormat(
          this.end_date
        );
        var prmTickEndDate = this.moment(prmEndDate).format("X");
        //console.log("End Date => "+ prmEndDate +" Time Span End Date "+prmTickEndDate );

        var rowDate = this.$functions.ConvertToEnglishDateFormat(
          el.created_date
        );
        var rowTSpanDate = this.moment(rowDate).format("X");
        //console.log("Columns Date => "+ rowDate+" Time Span Row Date " + rowTSpanDate)

        if (
          rowTSpanDate >= prmTickStartDate &&
          rowTSpanDate <= prmTickEndDate
        ) {
          this.filterConsumedActivities.push(el);
        }
      });
    },

    // Ben pasife çektim
    end_date(val) {
      this.filterConsumedActivities = [];
      this.configStart.maxDate = val; //başlangıç tarihinin son değeri en çok bitiş tarihi değerine eşit olabilir
      this.consumedActivities.map((el) => {
        var prmStartDate = this.$functions.ConvertToEnglishDateFormat(
          this.start_date
        );
        var prmTickStartDate = this.moment(prmStartDate).format("X");
        //console.log("Start Date => "+ prmStartDate + " Span Start Date"  + prmTickStartDate)
        var prmEndDate = this.$functions.ConvertToEnglishDateFormat(val);
        var prmTickEndDate = this.moment(prmEndDate).format("X");
        //console.log("End Date => "+ prmEndDate +" Time Span End Date "+prmTickEndDate );

        var rowDate = this.$functions.ConvertToEnglishDateFormat(
          el.created_date
        );
        var rowTSpanDate = this.moment(rowDate).format("X");
        //console.log("Columns Date => "+ rowDate+" Time Span Row Date " + rowTSpanDate)
        if (
          rowTSpanDate >= prmTickStartDate &&
          rowTSpanDate <= prmTickEndDate
        ) {
          this.filterConsumedActivities.push(el);
        }
      });
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.personData?.length;
  },

  methods: {
    getConsumedAcivitySales() {
      this.$database.CustomerProductSaleService.ConsumedAcivitySales(
        this.companyBranchID
      ).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          res.Result.map((el) => {
            let consumedActivitie = {};
            consumedActivitie.customer_product_sales_id =
              el.CustomerProductSalesId;
            consumedActivitie.product_priceId = el.ProductPriceId;
            consumedActivitie.trainer_id = el.TrainerId;
            consumedActivitie.trainer_person_id = el.TrainerPersonId;
            consumedActivitie.trainer_name = el.TrainerName;
            consumedActivitie.activity_id = el.ActivityId;
            consumedActivitie.activity_name = el.ActivityName;
            consumedActivitie.customer_person_id = el.CustomerPersonId;
            consumedActivitie.customer_name = el.CustomerName;
            consumedActivitie.email = el.Email;
            consumedActivitie.phone_number = el.PhoneNumber;
            consumedActivitie.quantity = el.Quantity;
            consumedActivitie.consumed_quantity = el.ConsumedQuantity;
            consumedActivitie.consumed_status = el.ConsumedStatus;

            (consumedActivitie.created_date = this.moment(el.CreatedDate)
              .locale(this.locale)
              .format("L")),
              this.consumedActivities.push(consumedActivitie);
          });
        }
        this.filterConsumedActivities = this.consumedActivities;
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat: (val) => {
      let sonucDeger;
      if (typeof val !== "undefined") {
        let date = val
          .split("T")[0]
          .split("-")
          .reverse()
          .join(".");
        let hour = val.split("T")[1].split("+")[0];
        let hourPart = hour.split(":");
        hour =
          hourPart[0] + ":" + hourPart[1] + ":" + hourPart[2].split(".")[0];
        sonucDeger = date + " " + hour;
      } else {
        sonucDeger = "Bilgi mevcut değil";
      }
      return sonucDeger;
    },

    cancelPayment(val, index) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Siparişi iptal etmek istediğinizden emin misiniz ?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$database.CustomerProductSaleService.CancelProductSale(val).then(
            (res) => {
              // console.log(res);
              if (!res.IsSuccess) {
                this.$functions.Messages.error("Action failed!");
                // this.productsSoldList[index].payment_is_canceled= true;
              } else {
                this.$functions.Messages.success(res.Result);

                this.productData = [];
                this.productBaseData = [];
                this.getProductList();
                this.productsSoldList[index].payment_is_canceled = true;
              }
            }
          );
        }
      });
    },
  },
  created() {
    // this.$route.meta.breadcrumb[0].title = 'newTitle'
    this.companyBranchID = this.$store.getters[
      "auth/userInfo"
    ].company_branch_id;
    this.start_date = this.moment()
      .add(-3, "month")
      .locale("tr")
      .format("L");
    this.end_date = this.moment()
      .add(1, "month")
      .locale("tr")
      .format("L"); //dateformat(new Date(), 'dd.mm.yyyy')

    this.getConsumedAcivitySales();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/productsSold.scss" scoped></style>
